<template>
  <div
    class="bundle-thumbnail-svg"
    :style="{ padding }"
  >
    <svg
      ref="svg"
      class="gallery-wall-thumbnail-svg"
      :class="{ 'crop': 'crop' }"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      :viewBox="viewBox"
      tabindex="-1"
    >
      <defs>
        <g ref="roomPatterns" />
        <g ref="framePatterns" />
      </defs>
      <g ref="roomShapes" />
      <g ref="frameShapes" />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';
import FrameSpec from '@framebridge/toolbox/FrameSpec';
import generateExtendedFrameSpec from '@framebridge/toolbox/generateExtendedFrameSpec';
import generateFramePreviewEl from '@framebridge/toolbox/generateFramePreviewEl';
import { svgPathCalculations } from '@framebridge/toolbox/generateSvgProperties';
import { primaryMatColors } from "@framebridge/toolbox/hardCodedValues";

const imageTypes = [
  'croppedAndNormalized',
  'customerSpecified',
  'normalized',
  'original',
  'originalUnedited',
  'normalizedUnedited'
];
const prependHost = (url, host) => {
  return url.startsWith('http') ? url : `${host}${url}`;
};
const prependHostToArtworkUrl = (artwork, host) => {
  return {
    dimensionsInPixels: artwork.dimensionsInPixels,
    url: prependHost(artwork.url, host)
  };
};
const prependHostToFrameSpecUrls = (frameSpec, host) => {
  return {
    ...frameSpec,
    artworks: frameSpec.artworks.map((artworkSpec) => ({
      ...artworkSpec,
      ...imageTypes.reduce((acc, imageType) => {
        const artwork = artworkSpec[imageType];
        return artwork
          ? {
            ...acc,
            [imageType]: prependHostToArtworkUrl(artwork, host)
          } : acc;
      }, {})
    })),
    moulding: {
      ...frameSpec.moulding,
      url: prependHost(frameSpec.moulding.url, host)
    }
  };
};
const parseOpeningsToArtworks = (bundleSpec) => {
  return {
    ...bundleSpec,
    components: bundleSpec.components.map((component) => {
      if (!component.frameSpec.mats.length) {
        return component;
      }
      const matName = component.frameSpec.mats[0].name || '';
      const hardCodedMat = primaryMatColors.find((mat) => mat.name === matName);

      if (
        !hardCodedMat
        || !hardCodedMat.openings
        || hardCodedMat.openings.length < 2
      ) {
        return component;
      }

      return {
        ...component,
        frameSpec: {
          ...component.frameSpec,
          artworks: hardCodedMat.openings.map((opening) => ({
            ...component.frameSpec.artworks[0],
            exterior: {
              widthInInches: opening.artworkWidthInInches,
              heightInInches: opening.artworkHeightInInches,
              depthInInches: 0,
              artworkOpeningWidthInInches: opening.widthInInches,
              artworkOpeningHeightInInches: opening.heightInInches
            },
            offset: {
              topInInches: opening.topInInches,
              leftInInches: opening.leftInInches
            }
          }))
        }
      };
    })
  }
};
const loadBundleSpec = async (bundleSpecNumber, joineryHost, authorizationToken) => {
  const authHeaders = authorizationToken ? { "Framebridge-Resource-Authorization-Token": authorizationToken } : {};
  const res = await axios.get(`${joineryHost}/api/v1/bundle_specifications/${bundleSpecNumber}`, { headers: authHeaders });
  return {
    ...res.data,
    authorizationToken: authorizationToken,
    components: res.data.components.map((component) => ({
      ...component,
      frameSpec: prependHostToFrameSpecUrls(component.frameSpec, joineryHost)
    }))
  };
};

const frameSpecsFromBundle = (bundleSpecData) => {
  return bundleSpecData.components.map((frame) => {
    return {
      ...frame.frameSpec,
      offsetInBundle: {
        leftInInches: frame.fromLeft,
        topInInches: frame.fromTop
      }
    };
  });
};
const initFrameSpecs = async (frameSpecs, joineryHost) => {
  return await Promise.all(frameSpecs.map(async (frameSpec) => {
    const fs = await FrameSpec.build({
      frameSpecData: frameSpec,
      number: frameSpec.id,
      joineryHost
    });

    return fs;
  }));
};

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    bundleSpecNumber: {
      type: String,
      required: false
    },
    token: {
      type: String,
      required: false
    },
    bundleSpec: {
      type: Object,
      required: false
    },
    previewImage: {
      type: String,
      required: false
    },
    padding: {
      type: String,
      default: '10%'
    },
    joineryHost: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      frameSpecs: null,
      frames: [],
      extendedFrameSpecs: [],
      xStart: 0
    };
  },
  computed: {
    viewBox () {
      const rightCorner = generateExtendedFrameSpec
        .findRightMostFramePlacement(this.extendedFrameSpecs);
      const lowestCorner = generateExtendedFrameSpec
        .findLowestFramePlacement(this.extendedFrameSpecs);
      const widthStop = this.frames.length && rightCorner !== undefined && rightCorner !== null
        ? rightCorner : 0;
      const heightStop = this.frames.length && lowestCorner !== undefined && lowestCorner !== null
        ? lowestCorner : 0;

      return `${this.xStart}, 0, ${widthStop - this.xStart}, ${heightStop}`;
    },
    svg () {
      return this.$refs.svg;
    }
  },
  methods: {
    async getExtendedFrameSpecs (frameSpecs) {
      return await Promise.all(
        (frameSpecs || this.frameSpecs).map(
          (minimalistFrameSpec) =>
            generateExtendedFrameSpec
              .forFrame(minimalistFrameSpec, 'horizontal')
        )
      );
    },
    async getFrames (extendedFrameSpecs) {
      return await Promise.all(
        (extendedFrameSpecs || this.extendedFrameSpecs).map(
          (extendedFrameSpec) => {
            const offsetRight = extendedFrameSpec.offsetInBundle.leftInInches;
            const offsetDown = extendedFrameSpec.offsetInBundle.topInInches;

            return svgPathCalculations(
              extendedFrameSpec,
              offsetRight,
              offsetDown,
              false
            )
          }
        ));
    },
    async parseFrameSpecs () {
      this.extendedFrameSpecs = await this.getExtendedFrameSpecs();
      this.frames = await this.getFrames();
    },
    async renderFrame ({
      frameKey,
      frame
    }) {
      const frameIDs = {
        gallery: this.id,
        frame: frameKey
      };
      const offset = this.extendedFrameSpecs[frameKey].offsetInBundle;
      const offsetRight = offset.leftInInches;
      const offsetDown = offset.topInInches;

      const patterns = generateFramePreviewEl.defs(frame, frameIDs);
      const group = generateFramePreviewEl.group(
        frame,
        frameIDs,
        false,
        undefined,
        offsetRight,
        offsetDown
      );

      this.svg.appendChild(patterns);
      this.svg.appendChild(group);
    },
    renderFrames () {
      for (const [frameKey, frame] of this.frames.entries()) {
        this.renderFrame({ frameKey, frame });
      }
    }
  },
  async mounted () {
    const joineryHost = this.joineryHost || import.meta.env.joineryHost;
    const bundleSpecData = this.bundleSpec
      ? this.bundleSpec
      : this.bundleSpecNumber && this.token
        ? await loadBundleSpec(
          this.bundleSpecNumber,
          joineryHost,
          this.token
        )
        : null;

    if (this.previewImage) {
      bundleSpecData.components[0].frameSpec.artworks[0].croppedAndNormalized.url = this.previewImage;
    }

    if (bundleSpecData) {
      const minimalistFrameSpecs = frameSpecsFromBundle({
        ...bundleSpecData,
        components: bundleSpecData.components.map((component) => ({
          ...component,
          frameSpec: prependHostToFrameSpecUrls(
            component.frameSpec,
            joineryHost
          )
        }))
      });
      this.frameSpecs = await initFrameSpecs(
        minimalistFrameSpecs,
        joineryHost
      );

      await this.parseFrameSpecs();
      this.xStart = Math.min(...bundleSpecData.components.map((x) => x.fromLeft));

      this.renderFrames();
    }
  }
});
</script>

<style lang="scss" scoped>
.bundle-thumbnail-svg {
  align-items: center;
  box-sizing: borderbox;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .gallery-wall-thumbnail-svg {
    outline: none;
  }
}
</style>
