<template>
  <div
    v-if="pricingData"
    class="tooltip"
    :class="isTooltipOpen ? 'tooltip--active' : ''"
  >
    <button
      type="button"
      class="btn-icon tooltip__button"
      @click="toggleTooltip(true)"
    >
      <i class="icon icon--information"></i>
      <span class="screenreader">Open price breakdown tooltip</span>
    </button>

    <div v-if="isTooltipOpen" class="tooltip__modal">
      <ul class="list-reset tooltip__list">
        <li
          v-for="(chargeItem) in pricingData.charges"
          :key="chargeItem.presentation"
          class="tooltip__item"
        >
          <span class="tooltip__item-key">
            {{ chargeItem.presentation }}
          </span>
          <span class="tooltip__item-value">
            ${{ chargeItem.amount }}
          </span>
        </li>
      </ul>
      <div class="tooltip__total">
        <span class="tooltip__total-text">
          Total
        </span>
        <span class="tooltip__total-text">
          ${{ pricingData.totalPrice }}
        </span>
      </div>
    </div>

    <div
      v-if="isTooltipOpen"
      class="tooltip-overlay"
      @click="toggleTooltip(false)"
    />
  </div>
</template>

<script>
import { ref, toRefs, onMounted } from 'vue';

export default {
  name: 'PriceBreakdownTooltip',
  props: {
    frameSpec: {
      type: Object,
      default: null,
    },
    pricing: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const isTooltipOpen = ref(false);
    const pricingData = ref(null);
    const {
      frameSpec,
      pricing,
    } = toRefs(props);

    /**
     * Toggle Tooltip
     * @param {Boolean} shouldOpen
     */
    const toggleTooltip = (shouldOpen) => {
      isTooltipOpen.value = shouldOpen;
    };

    onMounted(() => {
      if (frameSpec.value !== null) {
        pricingData.value = frameSpec.value.pricing;
      }
      if (pricing.value) {
        pricingData.value = pricing.value;
      }
    });

    return {
      isTooltipOpen,
      pricingData,
      toggleTooltip,
    };
  },
};

</script>
