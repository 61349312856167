<!-- eslint-disable max-len -->
<template>
  <div
    :class="{ 'is-loading': isLoading }"
    class="bag-gallery">
    <div
      v-if="frameSpec?.previewImage?.url && !isLoading"
      class="bag-gallery__uploaded bag-gallery__uploaded-image"
    >
      <ResponsiveImage
        :image="frameSpec.previewImage.url"
        :image-alt="title"
        :inlineIr="(true)"
      />
    </div>
    <div
      v-else-if="frameSpecWithMafMat"
      class="bag-gallery__uploaded"
    >
      <SingleFramePreviewSvg
        :frameSpec="frameSpecWithMafMat"
        class="bag-gallery__uploaded-shadow"
      />
    </div>
    <div
      v-else-if="bundleSpecNumber && bundleSpecToken && !isLoading"
      class="bag-gallery__bundle"
    >
      <BundleThumbnailSVGVanilla
        :id="lineItemId"
        :bundle-spec-number="bundleSpecNumber"
        :token="bundleSpecToken"
        :joinery-host="joineryHost"
        :croppedAndNormalizedUrl="croppedAndNormalizedUrl"
      />
    </div>
    <ResponsiveImage
      v-else-if="image && !isLoading"
      :image="image"
      :image-alt="title"
      :image-class="'bag-item__photo-img'"
      :inlineIr="(true)"
      :wrapperClass="'bag-item__photo-wrapper'"
    />
    <button
      v-if="!disableGallery"
      class="bag-gallery__zoom-btn btn-icon"
      @click.prevent="modalOpen = true"
    >
      <span class="screenreader">Open Photo Gallery</span>
      <span class="icon icon--zoom-out-half"></span>
    </button>
    <VueModal
      v-if="!disableGallery"
      :show="(modalOpen)"
      :includeOverlay="(false)"
      :isFullscreen="(true)"
      :afterOpen="afterOpen"
      @toggleModal="toggleModal"
    >
      <template #modal-content>
        <div class="vue-modal__body--fullscreen" v-html="galleryHtml"></div>
      </template>
    </VueModal>
    <div v-if="isCustomProduct && !isLoading && modalOpen">
      <Teleport to="#pvGalleryFirstSlide">
        <div
          v-if="frameSpec?.previewImage?.url"
          class="pv-gallery__item-upload pv-gallery__item-image"
        >
          <ResponsiveImage
            :image="frameSpec.previewImage.url"
            :image-alt="title"
            :inlineIr="(true)"
            :wrapperClass="'pv-gallery__item-upload-image'"
          />
        </div>
        <div v-else-if="frameSpec" class="pv-gallery__item-upload">
          <SingleFramePreviewSvg
            :frameSpec="frameSpec"
          />
        </div>
        <div
          v-else-if="bundleSpecNumber && bundleSpecToken"
          class="pv-gallery__item-upload"
        >
          <BundleThumbnailSVGVanilla
            :id="lineItemId"
            :frameSpec="frameSpec"
            :bundle-spec-number="bundleSpecNumber"
            :token="bundleSpecToken"
            :joinery-host="joineryHost"
            :croppedAndNormalizedUrl="croppedAndNormalizedUrl"
          />
        </div>
      </Teleport>
    </div>
  </div>
</template>
<script>
import {
  ref, onMounted, watch, computed,
} from 'vue';
import SingleFramePreviewSvg from '@framebridge/toolbox/SingleFramePreviewSvg';
import BundleThumbnailSVGVanilla from '@framebridge/toolbox/BundleThumbnailSVGVanilla';
import { mafMatOptions } from '@framebridge/toolbox/hardCodedValues';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';
import VueModal from '../common/modal/Modal';
import productGalleryZoomCarousel from '../../lib/product-gallery-zoom-carousel';

export default {
  name: 'CartItemGallery',
  components: {
    ResponsiveImage,
    SingleFramePreviewSvg,
    BundleThumbnailSVGVanilla,
    VueModal,
  },
  props: {
    image: {
      default: null,
      required: true,
      type: String,
    },
    title: {
      default: '',
      required: true,
      type: String,
    },
    url: {
      default: '',
      required: true,
      type: String,
    },
    frameSpec: {
      default: null,
      type: Object,
    },
    bundleSpecNumber: {
      type: String,
    },
    bundleSpecToken: {
      type: String,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    disableGallery: {
      default: false,
      type: Boolean,
    },
    lineItemId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const modalOpen = ref(false);
    const galleryHtml = ref(null);
    const url = ref(props.url);
    const croppedAndNormalizedUrl = ref(null);
    const joineryHost = ref(SDG.Data.joineryHost);

    const toggleModal = (value) => {
      modalOpen.value = value;
    };

    const getGallery = () => {
      if (!url.value) return;
      const upsellURL = `${url.value}&view=cart-gallery`;
      fetch(upsellURL)
        .then((res) => res.text())
        .then((galleryData) => {
          if (!galleryData || galleryData === '') return;
          galleryHtml.value = galleryData;
        })
        .catch((err) => console.error(err));
    };

    const afterOpen = () => {
      productGalleryZoomCarousel({
        dom: {
          carousel: '#vueModalRef .js-product-gallery-zoom-carousel',
          videoBtn: '.js-video-control',
        },
        arrangeImages: false,
      });
      SDG.videoHTML();
    };

    const updateHtmlWithPostArtwork = () => {
      const $div = document.createElement('div');
      // set elements html to gallery
      $div.innerHTML = galleryHtml.value;
      const $gallery = $div.children[0];

      if ($gallery) {
        const $firstSlide = $gallery.querySelector('.js-gallery-first-slide');

        if ($firstSlide) {
          __.addClass($firstSlide, 'hide');

          // update gallery html with the hidden image
          galleryHtml.value = $div.innerHTML;
        }
      }
    };

    const setCroppedUrl = (croppedUrl) => {
      croppedAndNormalizedUrl.value = croppedUrl;
    };

    onMounted(() => {
      if (props.disableGallery) return;
      getGallery();
    });

    const isCustomProduct = computed(() => {
      const isBundle = !!props.bundleSpecNumber && !!props.bundleSpecToken;
      const isCustomFrame = !!props.frameSpec;
      return isBundle || isCustomFrame;
    });

    watch(() => props.frameSpec, () => {
      if (props.frameSpec !== null) {
        setCroppedUrl(props.frameSpec?.artworks[0]?.croppedAndNormalized.url);
        updateHtmlWithPostArtwork();
      }
    });

    const frameSpecWithMafMat = computed(() => {
      const fs = props?.frameSpec?.clone();
      if (fs?.hasMat() && mafMatOptions.includes(fs.mats[0].name)) {
        fs.getArtwork().completedMafMatName = true;
      }
      return fs;
    });

    return {
      galleryHtml,
      modalOpen,
      toggleModal,
      afterOpen,
      frameSpecWithMafMat,
      isCustomProduct,
      joineryHost,
    };
  },
};
</script>
