<template>
  <Teleport :to="anchor">
    <section
      v-if="show"
      ref="modal"
      class="vue-modal"
      :class="show ? 'modal-open': ''"
      @keydown.esc="closeModal"
    >
      <div class="vue-modal__inner">
        <div
          class="vue-modal__container"
          :class="[
            isFullscreen ? 'vue-modal__container--fullscreen' : '',
            isAccount ? 'vue-modal__container--account' : '',
          ]"
        >
          <button
            v-if="isFullscreen"
            @click="closeModal"
            class="vue-modal__close vue-modal__close--fullscreen btn-icon modal__close"
            type="button"
          >
            <span class="vue-modal__close-text">
              Close
            </span>
            <i class="icon icon--close"></i>
          </button>
          <div v-else class="vue-modal__header">
            <button
              @click="closeModal"
              class="vue-modal__close btn-icon"
              type="button"
            >
              <i class="icon icon--close"></i>
              <span class="screenreader">Close</span>
            </button>
          </div>
          <div
            class="vue-modal__body"
            :class="isFullscreen ? 'vue-modal__body--fullscreen' : ''"
          >
            <slot name="modal-content"></slot>
          </div>
        </div>
      </div>
      <section
        v-if="(includeOverlay && show)"
        class="vue-modal__overlay"
        @click="closeModal"
      >
      </section>
    </section>
  </Teleport>
</template>

<script>
import { ref, onUpdated, Teleport } from 'vue';
import trapFocus from '../../../lib/trap-focus';

export default {
  name: 'VueModal',
  emits: ['toggleModal'],
  components: {
    Teleport,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    includeOverlay: {
      type: Boolean,
      default: true,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
    afterOpen: {
      type: Function,
      default: () => {},
    },
    afterClose: {
      type: Function,
      default: () => {},
    },
    anchor: {
      type: String,
      default: '#vueModalRef',
    },
  },
  setup(props, context) {
    const modal = ref(null);

    onUpdated(() => {
      if (props.show) {
        trapFocus(modal.value);
        __.addClass(document.body, 'overflow-hidden');
        if (props.afterOpen) {
          props.afterOpen();
        }
      } else {
        __.removeClass(document.body, 'overflow-hidden');
      }
    });

    const closeModal = () => {
      if (props.afterClose) {
        props.afterClose();
      }

      context.emit('toggleModal', false);
    };

    return {
      modal,
      closeModal,
    };
  },
};
</script>
