<template>
  <div class="acc">
    <button
      v-if="buttonPlacement === 'top'"
      :arial-controls="`accordion-content-${ariaTitle}`"
      :id="`accordion-control-${ariaTitle}`"
      @click.prevent="togglePanel"
      :class="buttonClass || ''"
      class="acc__btn"
      type="button"
    >
    <span
      v-if="title"
    >
      {{ title }}

      <span
          v-if="showPanel"
        >
          <slot name="opened-icon"></slot>
        </span>
        <span
          v-else
        >
          <slot name="closed-icon"></slot>
        </span>
      </span>
      <span
        v-else-if="htmlTitle"
        :class="htmlWrapperClass || ''"
      >
        <slot name="html-title"></slot>
        <span
          v-if="showPanel"
        >
          <slot name="opened-icon"></slot>
        </span>
        <span
          v-else
        >
          <slot name="closed-icon"></slot>
        </span>
      </span>
    </button>
    <Transition name="acc">
      <div
        :aria-hidden="!showPanel"
        :id="`content-${ariaTitle}`"
        v-show="showPanel"
      >
        <slot></slot>
      </div>
    </Transition>
    <button
      v-if="buttonPlacement === 'bottom'"
      :arial-controls="`accordion-content-${ariaTitle}`"
      :id="`accordion-control-${ariaTitle}`"
      @click.prevent="togglePanel"
      :class="buttonClass || ''"
      class="acc__btn"
      type="button"
    >
      {{ title }}
      <span
        v-if="showPanel"
      >
        <slot name="opened-icon"></slot>
      </span>
      <span
        v-else
      >
        <slot name="closed-icon"></slot>
      </span>
    </button>
  </div>
</template>

<script>
import { ref, Transition } from 'vue';

export default {
  name: 'VueAccordion',
  components: {
    Transition,
  },
  expose: ['showPanel', 'togglePanel', 'closePanel'],
  props: {
    title: {
      type: String,
    },
    htmlTitle: {
      type: Boolean,
      default: false,
    },
    htmlWrapperClass: {
      default: '',
    },
    ariaTitle: {
      type: String,
      required: true,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    buttonPlacement: {
      type: String,
      default: 'top',
    },
    defaltOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showPanel = ref(props.defaltOpen);
    const togglePanel = () => {
      showPanel.value = !showPanel.value;
    };
    const closePanel = () => {
      showPanel.value = false;
    };
    return {
      showPanel,
      togglePanel,
      closePanel,
    };
  },
};
</script>
<style lang="scss" scoped>
  .acc-enter-active,
  .acc-leave-active {
    transition: all 300ms ease-in-out;
  }

  .acc-enter-to,
  .acc-leave-from {
    overflow: hidden;
    max-height: 300px;
  }

  .acc-enter-from,
  .acc-leave-to {
    overflow: hidden;
    max-height: 0;
  }
</style>
